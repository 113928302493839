// extracted by mini-css-extract-plugin
export const grid = "BlogPost-module--grid--2LH52";
export const sidebarLeft = "BlogPost-module--sidebarLeft--dN1dh";
export const content = "BlogPost-module--content--3O-cY";
export const sidebarRight = "BlogPost-module--sidebarRight--qER3g";
export const image = "BlogPost-module--image--9Khcb";
export const subtitle = "BlogPost-module--subtitle--2-qz6";
export const meta = "BlogPost-module--meta--2jy7K";
export const recommended = "BlogPost-module--recommended--Lf2eo";
export const newsletterSubscribe = "BlogPost-module--newsletterSubscribe--3bXkq";
export const ad = "BlogPost-module--ad--2hjhp";